<template>
  <!-- <div class="content-field-phone"> -->
  <div class="global-select-icon">
    <!-- <v-form ref="form"> -->
    <v-row>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-select
          v-model="sCountryCallingCode"
          :items="aItemsCountryPhone"
          item-text="text"
          item-value="value"
          label="Código de país"
          class="global-select field-code-country"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
          @change="updateCountryCallingCode()"
        >
          <template slot="label">
            <span
              >Código de país
              <span v-show="bImportantDate" class="important-data"
                >*</span
              ></span
            >
          </template>
          <template slot="selection" slot-scope="data">
            <img
              class="mr-1"
              width="16px"
              height="16px"
              :src="data.item.icono"
            />
            <span class="text-counry-select">
              {{ " " + data.item.text }}
            </span>
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            <img class="mr-3" width="24" height="24" :src="data.item.icono" />
            <span class="text-counry-select">
              {{ "" + data.item.text }}
            </span>
          </template>
          <!-- <template slot="prepend-inner">
            <img width="24" height="24" :src="sFlagIcon" />
          </template> -->
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-text-field
          v-model="sAreaCallingCode"
          label="Código de área"
          placeholder="Código de área..."
          class="global-text-field field-code-area"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
          maxlength="3"
          @keyup="updateAreaCallingCode()"
          @keypress="fieldNumber($event)"
          @change="fieldNumber($event)"
          @paste="noLettersArea()"
        >
          <template slot="label">
            <span
              >Código de área<span
                v-show="bImportantDate"
                class="important-data"
                >*</span
              ></span
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-text-field
          v-model="sPhoneNumber"
          label="Teléfono"
          placeholder="Teléfono..."
          class="global-text-field field-phone"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
          maxlength="8"
          @keyup="updatePhoneNumber()"
          @keypress="fieldNumber($event)"
          @paste="noLettersPhone()"
        >
          <template slot="label">
            <span
              >Teléfono<span v-show="bImportantDate" class="important-data"
                >*</span
              ></span
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-text-field
          v-model="sPhoneExtension"
          label="Extensión(opcional)"
          placeholder="Extensión..."
          class="global-text-field field-extension"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
          maxlength="6"
          @keyup="updatePhoneExtension()"
          @keypress="fieldNumber($event)"
          @paste="noLettersExtension()"
          ref="numberText"
        >
          <template slot="label">
            <span>Extensión</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- </v-form> -->
  </div>
</template>
<script>
export default {
  props: {
    bClearTextFieldsPhone:Boolean,
    bImportantDate: Boolean,
    sCountryCallingCodeOrigin: String,
    sAreaCallingCodeOrigin: String,
    sPhoneNumberOrigin: String,
    sPhoneExtensionOrigin: String,
  },
  data() {
    return {
      emtpyDate: "---",
      textFieldRules: [(v) => !!v],
      textFieldRulesNot: [false],
      sFlagIcon: [require("@/assets/flag-mexico.png")],
      sCountryCallingCode: this.sCountryCallingCodeOrigin,
      sAreaCallingCode: this.sAreaCallingCodeOrigin,
      sPhoneNumber: this.sPhoneNumberOrigin,
      sPhoneExtension: this.sPhoneExtensionOrigin,
      aItemsCountryPhone: [
        {
          value: "52",
          text: "+52",
          icono: [require("@/assets/flag-mexico.png")],
        },
        {
          value: "1",
          text: "+1",
          icono: [require("@/assets/flag-united-states.png")],
        },
      ],
    };
  },
  beforeMount() {},
  methods: {
    noLettersArea() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.sAreaCallingCode);
        if (result == null) {
          this.sAreaCallingCode = "";
        } else {
          this.sAreaCallingCode = this.sAreaCallingCode;
        }
      }, 100);
    },
    noLettersPhone() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.sPhoneNumber);
        if (result == null) {
          this.sPhoneNumber = "";
        } else {
          this.sPhoneNumber = this.sPhoneNumber;
        }
      }, 100);
    },
    noLettersExtension() {
      setTimeout(() => {
        const regexExp = /^([0-9])+$/;
        let result = regexExp.exec(this.sPhoneExtension);
        if (result == null) {
          this.sPhoneExtension = "";
        } else {
          this.sPhoneExtension = this.sPhoneExtension;
        }
      }, 100);
    },
    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    updateCountryCallingCode() {
      this.$emit("updateCountryCallingCode", this.sCountryCallingCode);
    },
    updateAreaCallingCode() {
      this.$emit("updateAreaCallingCode", this.sAreaCallingCode);
    },
    updatePhoneNumber() {
      this.$emit("updatePhoneNumber", this.sPhoneNumber);
    },
    updatePhoneExtension() {
      this.$emit("updatePhoneExtension", this.sPhoneExtension);
    },
  },
  computed: {
    dialogAddProvider() {
      return this.$store.state.dialogAddProvider;
    },
    dialogAddAdministrator() {
      return this.$store.state.dialogAddAdministrator;
    },
  },
  watch: {
    sCountryCallingCode() {
      var ico = this.aItemsCountryPhone.find(
        ({ value }) => value === this.sCountryCallingCode
      ).icono;
      this.sFlagIcon = ico;
    },
    dialogAddProvider() {
      if (!this.dialogAddProvider) {
        this.sCountryCallingCode = "52";
        this.sAreaCallingCode = "";
        this.sPhoneNumber = "";
        this.sPhoneExtension = "";
        // this.$refs.form.reset();
      }
    },
    bClearTextFieldsPhone() {
      // if (!this.bClearTextFieldsPhone) {
        this.sCountryCallingCode = "52";
        this.sAreaCallingCode = "";
        this.sPhoneNumber = "";
        this.sPhoneExtension = "";
      // }
    },
  },
};
</script>

<style scoped>
.text-counry-select {
  color: var(--primary-color-text);
}
</style>
<style>
.field-code-country > .v-input__control > .v-input__slot {
  padding: 0 0px !important;
}
.field-code-country > .v-input__control > .v-input__slot .v-select__selections {
  /* padding: 0 0px !important; */
  margin-left: 5px;
}
</style>
